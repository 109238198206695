import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"

const BlogPostLayout = ({ data, location }) => {
  return (
    <Layout location={location}>
      <div
        dangerouslySetInnerHTML={{
          __html: data.markdownRemark.html,
        }}
      />

      {data.nextPost ? (
        <Link to={`posts/${data.nextPost.frontmatter.slug}`}>Next Post</Link>
      ) : null}
    </Layout>
  )
}

export const query = graphql`
  query BlogPostAndNext($slug: String!, $nextSlug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
    nextPost: markdownRemark(frontmatter: { slug: { eq: $nextSlug } }) {
      frontmatter {
        title
        slug
      }
    }
  }
`

export default BlogPostLayout
